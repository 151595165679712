.navigation_link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    height: 40px;
    gap: 16px;
    color: #8A8A8A;
    font-size: 14px;
    text-decoration: none;
    & > .navigation_content__icon {
        display: flex;
        align-items: center;
        & > svg {
            fill: #8A8A8A;
        }
    }
    &.active {
        background: #F1F7FE;
        color: #00AAF9;
        font-weight: 500;
        & > .navigation_content__icon > svg {
            fill: #00AAF9;
        }
    }
}