@use '../../../assets/styles/colors';

.custom-iteration {
  padding: 28px 40px;
  overflow-y: hidden;
  width: 518px;
  transition: all 1s ease-in;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .label {
      font-size: 20px;
      color: colors.$dark;
      font-weight: 500;
    }

    .subtitle {
      display: block;
      color: #aeaeae;
      font-size: 12px;
      font-weight: 400;
    }

    .close-btn {
      position: relative;
      cursor: pointer;
      border: 2px solid colors.$dark-blue;
      border-radius: 12px;
      width: 28px;
      height: 28px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        top: 50%;
        background-color: colors.$dark-blue;
        margin-top: -5px;
      }

      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &::before {
        right: 50%;
        transform: translateX(50%) rotate(-45deg);
      }
    }
  }

  &__container {
    > .day-reps {
      margin-top: 35px;
      font-weight: 400;
      font-size: 14px;
      display: block;

      &.hide {
        display: none;
      }

      > .label {
        color: #8a8a8a;
      }

      > .days {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 8px;

        > .day {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #f3f3f3;
          display: grid;
          font-weight: 400;
          font-size: 14px;
          place-items: center;
          transition: all 0.1s ease-in-out;
          cursor: pointer;

          &:not(&.selected):hover {
            background-color: #00aaf922;
          }

          &.selected {
            background-color: #00aaf9;
            color: #f3f3f3;
          }
        }
      }
    }

    > .ends {
      margin: 35px 0;

      .label {
        font-weight: 400;
        font-size: 14px;
        color: #8a8a8a;
      }

      .option {
        display: flex;
        align-items: center;
        padding-right: 120px;
        justify-content: space-between;
        margin-top: 15px;

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
        .css-1cyypew-MuiInputBase-root-MuiOutlinedInput-root {
          height: 40px;
          border-radius: 12px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
