@use './Members';
@use './Teachers';
@use './Classes';
@use './Subjects';
@use './Offers';
@use './Levels';
@use './Activities';
@use './StreamingTools/StreamingTools';
@use './CategoryList';
@use './Login';
@use './MyCalendar';
@use './NotFound';

.view_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.calendar__view_container {
  display: flex;
  flex-grow: 1;
}
