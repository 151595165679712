.main_sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 272px;
  min-width: 272px;
  border-right: 1px solid #e7e7e7;
  height: 100vh;
  overflow: auto;
  background: #ffffff;
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
  }
  & > .main_sidebar__column {
    display: flex;
    flex-direction: column;
    position: relative;
    & > .main_sidebar__header {
      display: flex;
      justify-content: space-between;
      padding: 0 32px;
      margin-top: 20px;
      align-items: center;
      & > .main_sidebar__logo_container {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      & > .main_sidebar__toggle_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        transition: all 300ms ease;
        transform: translateX(24px);
        cursor: pointer;
        & > svg {
          stroke: #00aaf9;
        }
        &:hover {
          background: #f1f7fe;
        }
      }
    }
    & > .main_sidebar__navigation {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
      margin-top: 31px;
      padding-bottom: 23px;
      border-bottom: 1px solid #e7e7e7;
    }
  }
  &.main_sidebar__mini {
    width: 82px;
    min-width: 82px;
    & > .main_sidebar__column {
      & > .main_sidebar__header {
        padding: 0;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        & > .main_sidebar__logo_container {
          & > .main_sidebar__logo_container__typo {
            display: none;
          }
        }
        & > .main_sidebar__toggle_btn {
          transform: translateX(0px);
          & > svg {
            transform: rotate(180deg);
          }
        }
      }
      & > .main_sidebar__navigation {
        padding: 0 18px;
        padding-bottom: 23px;
        margin-top: 10px;
        & > .navigation_link {
          padding: 0 14px;
          & > .navigation_content__label {
            display: none;
          }
        }
      }
    }
  }
  &.main_sidebar__hidden {
    display: none;
  }
}
