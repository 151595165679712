.table_actions {
  display: flex;
  align-items: center;
  gap: 18px;
  & > .icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    transition: all 300ms ease;
    cursor: pointer;
    & > svg {
      fill: #aeaeae;
    }
    &:hover {
      border: 1px solid #e7e7e7;
    }
  }
}
