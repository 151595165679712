.view_title__search {
  display: flex;
  align-items: center;
  & > .search_field {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 0 10px;
    padding-right: 20px;
    outline: none;
    min-width: 40px;
    transform: translateX(10px);
    width: 175px;
    min-width: 175px;
    & > svg {
      fill: #aeaeae;
    }
    & > input {
      outline: none;
      border: none;
      width: 100%;
      color: #000000;
      font-size: 15px;
      &::placeholder {
        color: #878f9d;
        font-size: 15px;
      }
    }
  }
  & > .view_title_btn {
    z-index: 2;
  }
}
