.custom-input {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 0;
  margin-top: 10px;
  position: relative;

  &-error {
    border: 1px solid red;
    border-radius: 12px;
    padding: 6px 4px;
  }

  &__icon {
    margin-right: 5px;
    background-color: #f3f3f3;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: grid;
    place-items: center;
    fill: #aeaeae;
  }

  &__divider {
    width: 2px;
    height: 20px;
    background-color: #8a8a8a;
    margin: 0 10px;
    border-radius: 1px;
  }

  &__input {
    border: none;
    flex-grow: 1;
    height: 100%;
    font-size: 16px;
    outline: none;
    background-color: transparent;
    position: relative;
    color: #8a8a8a;
    font-size: 15px;
    font-weight: 400;

    &::placeholder {
      color: #cdcdcd;
    }
  }

  &__text-error {
    position: absolute;
    bottom: -101%;
    left: 0;
    font-size: 11px;
    color: #ED3863;
    font-weight: 500;
    height: 100%;
  }
}