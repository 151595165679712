@use "../../../../assets/styles/colors";

.access-denied {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    margin-top: -100px;

    &__container {
        display: flex;
        align-items: center;
        animation: floatImage 6s ease-in-out infinite;

        p {
            color: #4d4d4d;
            font-size: 24px;
            margin-top: 20px;
            letter-spacing: 1px;
            font-weight: 40;
        }

        img {
            width: 300px;
            height: 300px;
            filter: drop-shadow(0 3mm 4mm #4d4d4d76);
        }
    }

    @keyframes floatImage {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY(0);
        }
    }
}