.category_list_table__row {
  & > .title_column {
    & > .title {
      font-size: 14px;
    }
  }
  & > .text {
    font-size: 14px;
    &.muted {
      color: #8a8a8a;
    }
  }
}
