.tool_card {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  & > .tool_card__body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    & > .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .col {
        display: flex;
        gap: 16px;
        & > .group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & > .title {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
          }
          & > .subtitle {
            font-size: 14px;
            color: #575757;
          }
        }
      }
      & > .icon_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        transition: all 300ms ease;
        cursor: pointer;
        & > svg {
          fill: #8a8a8a;
        }
        &:hover {
          background: #f5f5f5;
        }
      }
    }
    & > .desc {
      font-size: 14px;
      color: #8a8a8a;
    }
  }
  & > .tool_card__footer {
    padding: 10px;
    border-top: 1px solid #e7e7e7;
    height: 44px;
    display: flex;
    flex-direction: row-reverse;
    & > .link {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #00aaf9;
      transition: all 300ms ease;
      cursor: pointer;
      &:hover {
        color: darken(#00aaf9, 10%);
      }
    }
  }
}
