.input {
    display: flex;
    flex-direction: column;

    &.error {
        .input__field {
            border-color: #ED3863;

            &::placeholder {
                color: #ED3863;
            }
        }
    }

    &__label {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #8a8a8a;
        margin-bottom: 5px;
        line-height: 24px;
        margin-top: 15px;
    }

    &__field {
        border-radius: 12px;
        border: 1px solid #E8E8E8;
        padding: 10px 15px;
        height: 50px;

        &::placeholder {
            color: #717171;
            font-size: 15px;
            font-weight: 400;
        }

        &:focus {
            outline: none;
            border-color: #CDCDCD
        }
    }

    &__text-error {
        font-size: 11px;
        color: #ED3863;
        font-weight: 500;
    }
}