.class_item {
  display: flex;
  align-items: center;
  min-width: 220px;

  & > .class_item__label {
    font-size: 14px;
    color: #434343;
    transform: translateX(-6px);
  }

  & > .class_item__dot {
    width: 5px;
    height: 5px;
    background: #8a8a8a;
    border-radius: 20px;
    margin-left: 6px;
  }
}
