.login__container {
  width: 100%;
  margin: auto;
  height: 100vh;
  background-color: #F1F7FE;
  background-image: linear-gradient(131deg, rgba(241, 247, 254, 1) 1%, rgba(247, 247, 247, 1) 100%);
}

.login_form__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}



.img_component {
  width: 5rem;
}