.calendar_filtres {
  display: flex;
  flex-direction: column;
  width: 260px;
  min-width: 260px;
  border-right: 1px solid #e7e7e7;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  background: #ffffff;
  transition: all .5s ease-in-out;
  position: relative;

  &.close {
    min-width: 0;
    width: 0;
    overflow: hidden;
  }

  &>.row {
    display: flex;
    justify-content: flex-end;
    margin: 12px 12px 0 0;

    &>.icon_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      transition: all 300ms ease;
      cursor: pointer;

      &>svg {
        stroke: #00aaf9;
      }

      &:hover {
        background: #f1f7fe;
      }
    }
  }

  &>.calendar {
    margin: 0 12px;
  }
}