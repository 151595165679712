.modal {
    background: #FFFFFF;
    border-radius: 12px;
    width: 518px;
    padding: 28px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-header {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:last-child {
            cursor: pointer;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 20px;

        p:first-child {
            text-transform: capitalize;
        }

        p {
            font-weight: 500;
            font-size: 18px;
        }

        p:last-child {
            font-weight: 400;
            font-size: 12px;
            color: #AEAEAE;
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            width: calc(50% - 6px);
            padding: 12.5px 0;
            cursor: pointer;
            color: #8A8A8A;
            height: 50px;
        }

        button:last-child {
            color: white;
            background-color: #ED3863;
        }
    }
}