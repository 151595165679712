.loader {
  background-color: transparent;
  overflow: hidden;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.loader__element {
  border-radius: 100%;
  border: 3px solid white;
  margin: 5px;
}

.loader__element:nth-child(1) {
  animation: preloader 0.6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}

@keyframes preloader {
  100% {
    transform: scale(2);
  }
}
