@use "../../../assets/styles/colors";

.button {
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: #FFF;
    height: 50px;
    text-transform: capitalize;
    border-radius: 8px;
    background-color: colors.$primary-blue;
    margin-top: 15px;
    transition: all .3s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #0098DE;
    }

    &:disabled {
        background-color: #3685ac;
        cursor: not-allowed;
    }
}