.repeat-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 13px;

    &__label {
        font-weight: 400;
        font-size: 15px;
        color: #4D4D4D;
        white-space: nowrap;
    }

    &__reps {
        display: flex;
        align-items: center;
        padding: 0 10px;
        border: 1px solid #E8E8E8;
        border-radius: 12px;
        height: 50px;
    }

    &__select {
        margin-top: unset;
    }
}