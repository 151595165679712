.view_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 15px;
  & > .view_title__column {
    display: flex;
    align-items: center;
    gap: 14px;
    & > .title {
      font-size: 18px;
      font-weight: 500;
      color: #373737;
    }
    & > .view_title__toggle_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      transition: all 300ms ease;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      cursor: pointer;
      & > svg {
        stroke: #8a8a8a;
      }
    }
  }
}
