$primary-blue: #00AAF9;
$light-blue: #F1F7FE;
$dark-blue: #363853;
$primary-gray: #8A8A8A;
$warning: #ED3863;
$dark: #000000;
$light-black: #303030;
$accent-color: #262626;
$background-text: #F3F3F3;
$gray: #D9D9D9;
$extra-light-gray: #E7E7E7;
$light-gray: #cecece;