.responsive_container {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: auto;
  & > .table_container {
    position: absolute;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    & > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      padding: 0 25px;
      height: 78px;
      border-bottom: 1px solid #e7e7e7;
      & > * {
        display: flex;
        white-space: nowrap;
        flex-grow: 1;
        flex-basis: 0;
      }
      & > :last-child {
        justify-content: flex-end;
      }
      &.table__header {
        background: #f3f3f3;
        height: 43px;
        font-size: 14px;
        color: #8a8a8a;
        border-bottom: none;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 2;
      }
    }
  }
}
