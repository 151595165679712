.offers_table__row {
  & > .title_column {
    & > .MuiAvatar-root {
      width: 46px;
      height: 46px;
      background: #d9d9d9;
    }
    & > .title {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  & > .classes_column {
    display: flex;
    gap: 12px;
  }
}
