.table__select {
  max-width: 258px;
  width: 258px;
  height: 48px;
  outline: none;
  & > input {
    display: none;
  }
  & > .MuiSelect-select {
    padding: 0 0;
    height: 100% !important;
    padding-right: 40px !important;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-size: 14px;
  }
  & > fieldset {
    border: 1px solid #e7e7e7 !important;

    border-radius: 8px;
    top: 0;
    & > legend {
      line-height: 0 !important;
    }
  }
  &:hover {
    & > fieldset {
      border: 1px solid #e7e7e7 !important;
    }
  }
}

.table__chip {
  height: 27px !important;
  background: #f1f7fe !important;
  & > .MuiChip-label {
    color: #00aaf9;
    font-size: 12px;
  }
}
