.add_tool_card {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 36px 48px;
  flex-grow: 1;
  flex-basis: 0;
  & > svg {
    fill: #cdcdcd;
    width: 36px;
  }
  & > .description {
    color: #8a8a8a;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
