@use "../../../../assets/styles/colors";

.current-day {
    display: flex;
    align-items: center;


    &__arrow {
        display: flex;
        align-items: center;

        svg {
            width: 18px;
            height: 20px;
            fill: #363853;
            cursor: pointer;
        }

        &--left {
            margin-right: 20px;
        }

        &--right {
            margin-left: 20px;
        }
    }

    &__toogle-calendar {
        margin-left: -10px;
        margin-right: 10px;
        cursor: pointer;
    }

    &__date {
        width: 165px;
        height: 38px;
        border: 1px solid #E7E7E7;
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        color: #363853;
        font-weight: 400;
        font-size: 15px;
        white-space: nowrap;

    }

    &__calendar {
        border: 1px solid #E7E7E7;
        border-left: none;
        border-radius: 0 8px 8px 0;
        width: 40px;
        height: 38px;
        display: grid;
        place-content: center;
        cursor: pointer;


        svg path {
            fill: colors.$primary-blue;

        }
    }
}