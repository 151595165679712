.css-tj5bde-Svg {
  fill: #777 !important;
}
.multi-select__label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #8a8a8a;
  margin-bottom: 5px;
  line-height: 24px;
  margin-top: 15px;
}

