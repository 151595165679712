@use '../../../../assets/styles/colors'as *;

.header {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;

}

.sub_header {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: auto;
  margin-bottom: 1rem;
  color: $primary-gray;
}

.login_form {
  max-width: 33rem;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgba(5, 25, 45, 0.3);

  .img_container {
    margin: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }


  &__body {
    padding: 2rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 2rem;
  }

  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.9rem;
    align-items: stretch;
    // color: $light-black;
    font-weight: 400;

    input {
      padding: 1rem;
      border: 1px solid $light-gray;
      border-radius: 0.5rem;
      outline: none;
      transition: all 0.2s ease-in-out;

      &:focus {
        border: 1px solid $primary-blue;
      }

      &::placeholder {
        color: $light-gray;
        font-size: 0.95rem;
        font-weight: 400;
      }
    }

    .input_errors {
      color: $warning;
      font-size: 0.8rem;
      font-weight: 400;
      position: absolute;
      top: 5.5rem;
    }
  }

  &__submit {
    background-color: $primary-blue;
    padding: 1rem;
    height: 3.5rem;
    color: white;
    font-weight: 500;
    font-size: 0.95rem;
    border-radius: 0.5rem;
    border: 1px solid $primary-blue;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: $primary-blue, $alpha: 0.6);
    }
  }
}

// .error {
//   border: 1px solid $warning !important;
// }