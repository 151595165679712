.levels_table__row {
  & > .title_column {
    & > .title {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  & > .classes_column,
  & > .subjects_column {
    display: flex;
    gap: 12px;
  }
}
