.calendar_navigation {
    display: flex;
    gap: 30px;
    height: 38px;
    width: 100%;
    border-bottom: 1px solid #E7E7E7;
    padding: 0 30px;
    & > .calendar_navigation_link {
        text-decoration: none;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        height: 100%;
        line-height: 38px;
        &.active {
            color: #00AAF9;
            font-weight: 500;
            &::after {
                display: flex;
                content: "";
                width: 100%;
                height: 3px;
                background: #00AAF9;
                position: absolute;
                bottom: 0;
                border-radius: 20px;
            }
        }
    }
}