@use '../../../assets/styles/colors';

.add-schedule {
  min-width: 350px;
  padding: 28px 40px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .label {
      font-size: 20px;
      color: colors.$dark;
      font-weight: 500;
    }

    .subtitle {
      display: block;
      color: #aeaeae;
      font-size: 12px;
      font-weight: 400;
    }

    .close-btn {
      position: relative;
      cursor: pointer;
      border: 2px solid colors.$dark-blue;
      border-radius: 12px;
      width: 28px;
      height: 28px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        top: 50%;
        background-color: colors.$dark-blue;
        margin-top: -5px;
      }

      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &::before {
        right: 50%;
        transform: translateX(50%) rotate(-45deg);
      }
    }
  }
}