@use '../../../assets/styles/colors';

.event-modal {
  min-width: 462px;
  overflow-y: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0;

    .desc {
      margin-bottom: 15px;

      &__title {
        font-size: 18px;
        font-weight: 500;
        color: colors.$dark;
        margin-bottom: 4px;
      }

      &__date {
        font-size: 14px;
        color: #575757;
        font-weight: 400;
      }
    }

    .close-btn {
      position: relative;
      cursor: pointer;
      border: 2px solid #cdcdcd;
      border-radius: 12px;
      display: inline-block;
      width: 28px;
      height: 28px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        top: 50%;
        background-color: #cdcdcd;
        margin-top: -5px;
      }

      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &::before {
        right: 50%;
        transform: translateX(50%) rotate(-45deg);
      }
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 5px;

    .offer {
      height: 40px;
      min-width: 100px;

      &.popover {
        color: #656565;
        font-size: 12px;
        font-weight: 400;
        gap: 4px;
        padding: 5px 7px;
        height: 25px;

        &__image {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid #e7e7e7;
        }

        &__name {
          color: #656565;
          font-weight: 400;
        }
      }
    }

    .teacher,
    .offer {
      color: #656565;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;

      &__profile-pic,
      &__image {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        border: 1px solid #e7e7e7;
      }

      &__name,
      &__name {
        color: #656565;
        font-weight: 500;
      }
    }

    .offer__image {
      width: 24px;
      height: 24px;
      border: none;
      filter: hue-rotate(200deg);
    }
  }

  &__about {
    padding: 20px 15px;

    .session-info {
      color: #aeaeae;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
    }


    .category {
      font-size: 14px;
      font-weight: 400;
      color: #575757;
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;

      .popover__content {
        margin-top: 6px;
        margin-left: 8px;
        max-height: 120px;
        overflow: auto;
        min-width: 150px;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 50%;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: colors.$primary-blue;
        }

        .category__info {
          margin-top: 7px;
          margin-left: 0;
          height: 22px;
          font-size: 11px;
          font-weight: 500;
          ;

        }
      }

      div:first-of-type {
        padding-top: 3px;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .infos-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &__info {
        display: block;
        margin-left: 10px;
        height: 27px;
        border-radius: 100px;
        padding: 4px 10px;
        color: colors.$primary-blue;
        background-color: colors.$light-blue;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$light-blue;
    height: 53px;
    padding: 15px;

    .text {
      font-size: 14px;
      font-weight: 400;
      color: #575757;
    }

    .btns-container {
      display: flex;
      align-items: center;
      width: fit-content;
      gap: 14px;

      button {
        background-color: #fff;
        height: 39px;
        width: 101px;
        margin-top: 0;
        border-radius: 8px;
      }

      .delete {
        border: 1px solid colors.$warning;
        color: colors.$warning;
      }

      .reschedule {
        border: 1px solid colors.$primary-blue;
        color: colors.$primary-blue;
      }
    }
  }
}