* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  user-select: none;
}

.dummy {
  padding: 12px 30px;
}

ul {
  list-style: none;
}

.not_resp_yet {
  @media only screen and (max-width: 1079px) {
    display: none !important;
  }
}