.calendar_responsive_container {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: auto;

  & > .calendar_events_container {
    position: absolute;
    min-width: 100%;
    display: flex;

    & > .calendar__classes {
      display: flex;
      position: relative;

      & > :first-child > .calendar__headers__class__header {
        border-left: 1px solid #e8e8e8;
      }

      & > .calendar__headers__class {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 800px;
        width: 330px;
        overflow: hidden;

        & > .calendar__headers__class__header {
          display: flex;
          justify-content: center;
          align-items: center;
          position: sticky;
          top: 0;
          width: 100%;
          height: 38px;
          min-height: 38px;
          background: #f7f7f7;
          border-right: 1px solid #e8e8e8;
          border-bottom: 1px solid #e8e8e8;
          font-size: 14px;
          font-weight: 500;
          color: #8a8a8a;
          margin-bottom: 12px;
          z-index: 4;
          .close-btn__container {
            transition: all 0.1s ease-in-out;
            &:hover {
              .right-bar,
              .left-bar {
                opacity: 1;
              }
            }
          }

          .close-btn {
            position: relative;
            cursor: pointer;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            transition: all 0.1s ease-in-out;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.05);
            }

            .right-bar,
            .left-bar {
              position: absolute;
              width: 2px;
              height: 15px;
              top: 50%;
              margin-top: -7.5px;
              opacity: 0.5;
            }

            .right-bar {
              left: 50%;
              transform: translateX(-50%) rotate(45deg);
            }

            .left-bar {
              right: 50%;
              transform: translateX(50%) rotate(-45deg);
            }
          }
        }

        & > .calendar__headers__class__timezone_background {
          display: flex;
          width: 100%;
          height: 67px;
          min-height: 67px;
          border-top: 1px solid #e7e7e7;
          position: relative;
          z-index: -2;

          &::after {
            content: '';
            border-top: 1px dashed #e7e7e7;
            width: 100%;
            top: 33px;
            position: absolute;
          }
        }

        & > .calendar__headers__class__timezone_event {
          background: #ffffff;
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          margin-left: 4px;
          display: flex;
          width: 291px;
          height: 92px;
          //   min-height: 95px;
          z-index: 0;
          position: absolute;
          top: 55px;
          transition: all 0.3s linear;
          overflow: hidden;
          cursor: pointer;

          &:hover {
            width: 291px !important;
            z-index: 1;
            left: 0 !important;
          }
        }
      }
    }

    & > .calendar__timeline {
      display: flex;
      flex-direction: column;
      gap: 46px;
      padding-bottom: 60px;
      width: 60px;
      background: #f7f7f7;
      position: sticky;
      left: 0;
      z-index: 5;

      &::before {
        content: '';
        width: 100%;
        height: 37px;
        border-bottom: 1px solid #e8e8e8;
        background: #f7f7f7;
        position: sticky;
        top: 0;
        left: 0;
      }

      & > :first-child {
        margin-top: -44px;
      }

      & > .calendar__timeline__item {
        font-size: 14px;
        color: #575757;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
