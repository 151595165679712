.loading {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;

}

@keyframes ldio-k1xqovo44b {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.ldio-k1xqovo44b div {
  box-sizing: border-box !important
}

.ldio-k1xqovo44b>div {
  position: absolute;
  width: 56.279999999999994px;
  height: 56.279999999999994px;
  top: 72.35999999999999px;
  left: 72.35999999999999px;
  border-radius: 50%;
  border: 4.02px solid #000;
  border-color: #00aaf9 transparent #00aaf9 transparent;
  animation: ldio-k1xqovo44b 0.9345794392523364s linear infinite;
}

.ldio-k1xqovo44b>div:nth-child(2) {
  border-color: transparent
}

.ldio-k1xqovo44b>div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldio-k1xqovo44b>div:nth-child(2) div:before,
.ldio-k1xqovo44b>div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4.02px;
  height: 4.02px;
  top: -4.02px;
  left: 22.11px;
  background: #00aaf9;
  border-radius: 50%;
  box-shadow: 0 52.25999999999999px 0 0 #00aaf9;
}

.ldio-k1xqovo44b>div:nth-child(2) div:after {
  left: -4.02px;
  top: 22.11px;
  box-shadow: 52.25999999999999px 0 0 0 #00aaf9;
}

.loadingio-spinner-dual-ring-ne4sspbrfmf {
  width: 201px;
  height: 201px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}

.ldio-k1xqovo44b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-k1xqovo44b div {
  box-sizing: content-box;
}