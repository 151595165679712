.popover {
    position: relative;



    &__trigger {
        cursor: pointer;
    }


    &__content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0 8px 8px 8px;
        transform: scale(0);
        transform-origin: top center;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 0rem 0.5rem rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-color: #fff;
        z-index: 1;

        .offer {
            color: #656565;
            font-size: 12px;
            font-weight: 400;
            gap: 4px;
            height: 25px;
            width: 100%;




            &__image {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 1px solid #e7e7e7;
            }

            &_name {
                color: #656565;
                font-weight: 400;
                white-space: nowrap;
                display: block;
                overflow: hidden;
                width: calc(100% - 20px);
                text-overflow: ellipsis;
                padding-right: 10px;
            }
        }


        &--visible {
            transform: scale(1);
        }
    }
}