.calendar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 224px;
  & > .calendar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .calendar__header__col {
      display: flex;
      gap: 24px;
      & > .calendar__header__label {
        font-size: 18px;
        color: #0f2552;
      }
      & > .calendar__header__icon_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        cursor: pointer;
        & > svg {
          fill: #848a95;
        }
      }
    }
  }
  & > .calendar__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > .calendar__body__row {
      display: flex;
      gap: 9px;
      &.calendar__body__row_header {
        justify-content: space-between;
        gap: 0;
      }
      //   justify-content: space-between;
      & > .calendar__body__col {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &.empty {
          cursor: default;
        }
        &.calendar__body__labels {
          font-size: 10px;
          font-weight: 500;
          color: #7e818c;
        }
        &.calendar__body__numbers {
          font-size: 13px;
          color: #0f2552;
          &.today {
            background: #00aaf9;
            border-radius: 40px;
            color: #fdfdfd;
          }
        }
      }
    }
  }
}
