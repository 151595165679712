.user_activities__divider {
  display: flex;
  align-items: center;
  margin: 18px 0 !important;
  &::after,
  &::before {
    top: 0 !important;
    border-top: 1px solid #e7e7e7;
  }
  & > .MuiDivider-wrapper {
    padding: 0;
    & > .MuiChip-root {
      border: 1px solid #e7e7e7;
      background: #ffffff;
      color: #4d4d4d;
      font-size: 14px;
    }
  }
}

.user_activities__group {
  display: flex;
  flex-direction: column;
  padding: 0 94px;
  margin: 42px 0;
  gap: 30px;
  @media only screen and (max-width: 1279px) {
    padding: 0 25px;
  }
  & > .user_activity__container {
    position: relative;
    display: flex;
    gap: 12px;
    align-items: center;
    & > .icon_container {
      display: flex;
      justify-content: center;
      align-items: center;
      & > svg {
        fill: #00aaf9;
      }
    }
    & > .MuiAvatar-root {
      width: 34px;
      height: 34px;
    }
    & > .user_activity__auhtor {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    & > .user_activity__desc {
      font-size: 14px;
      color: #575757;
    }
    & > .user_activity__time {
      font-size: 14px;
      font-weight: 500;
      color: #8a8a8a;
    }
  }
  & > *:not(:last-child) {
    &::after {
      position: absolute;
      content: '';
      width: 2px;
      height: 30px;
      background: #e7e7e7;
      left: 16px;
      top: 34px;
      @media only screen and (max-width: 1279px) {
        display: none;
      }
    }
  }
}

.empty_array__message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #4d4d4d;
}
