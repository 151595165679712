.sidebar__content-workspace {
  max-width: 238px;
  width: 100%;
  border: 1px solid #dedede;
  background-color: white;
  border-radius: 14px;

  display: flex;
  flex-direction: column;

  &.mini {
    width: calc(100% - 32px);
  }

  &-default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    height: 54px;
    padding-right: 19px;
    &.mini {
      padding-right: 0;
      justify-content: center;
      height: 46px;
    }

    cursor: pointer;
    .sidebar__content-workspace-field {
      &:hover {
        background-color: transparent;
      }
    }
  }
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    &.mini {
      display: none;
    }
  }
  &-field-content {
    display: flex;
    flex-direction: column;
  }
  &-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    border-radius: 8px;
    direction: rtl;
    padding: 0.5rem;
    &:hover {
      background-color: rgba(238, 238, 238, 0.503);
    }
    cursor: pointer;
    .field-thumbnail {
      display: flex;
      align-items: center;
      width: 31px;
      height: 31px;
      &.mini {
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
    .field-name {
      p {
        font-weight: 700;
        font-size: 16px;
      }
      &.mini {
        display: none;
      }
    }
  }
}
