.member_table__row {
  & > .title_column {
    & > .MuiAvatar-root {
      width: 46px;
      height: 46px;
      background: #d9d9d9;
    }
    & > .title {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}

.header_column__check_and_title {
  display: flex;
  align-items: center;
  gap: 4px;
}
